/*
 * Copyright 2021 by Avid Technology, Inc.
 */
import createStylesElement from './createStylesElement';
import styles from './styles/sso-area-styles.scss';

import localizationStorage from '../localization/storage';

export function createKerberosButton({ onLogin }) {
    const kerberosButton = createStylesElement({
        el: 'button',
        id: 'sso-button',
        classNames: [styles['sso-button']]
    });
    kerberosButton.innerText = localizationStorage.getLocalization('sso-sign-in-button');
    kerberosButton.addEventListener('click', onLogin);

    return {
        getElement() {
            return kerberosButton;
        },
        focus() {
            if (kerberosButton) {
                kerberosButton.focus();
            }
        },
        destroy() {
            kerberosButton.removeEventListener('click', onLogin);
            kerberosButton.remove();
        }
    };
}

export function createSsoArea() {
    const area = createStylesElement({
        el: 'fieldset',
        classNames: [styles.container]
    });
    const legend = createStylesElement({
        el: 'legend',
        classNames: [styles['sso-legend']]
    });
    legend.innerText = localizationStorage.getLocalization('sso-sign-in-label');
    area.appendChild(legend);

    return {
        getElement() {
            return area;
        },
        append(button) {
            area.append(button);
        }
    };
}
